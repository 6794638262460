<template>
  <v-card flat class="ma-3">
    <v-app-bar absolute extended flat color="white" scroll-target="#scrollBody">
      <v-tabs
        v-model="tab"
        color="primary"
        icons-and-text
        height="80"
        class="mt-5"
      >
        <v-tabs-slider color="amber"></v-tabs-slider>
        <v-tab
          v-for="(projectTab, index) in projectTabs"
          :key="index"
          class="mx-2"
          :disabled="!checkInputValid(index)"
        >
          <small class="mt-2">{{ projectTab.subtitle }}</small>
          <span class="mt-1">{{ projectTab.title }}</span>
          <v-icon color="secondary"
            >mdi-{{
              checkIconValid(index)
                ? "check-circle"
                : "checkbox-blank-circle-outline"
            }}</v-icon
          >
        </v-tab>
      </v-tabs>
    </v-app-bar>
    <v-sheet id="scrollBody" class="scroller">
      <v-container fluid>
        <v-tabs-items v-model="tab" class="no-transition mt-16 pt-5">
          <v-tab-item transition="false">
            <UserCompanyPage
              ref="user"
              :editMode="editMode"
              :projectUser="projectUser"
              :projectCompany="projectCompany"
              :authenticatedUser="user"
              :inputValid="!!checkInputValid(1)"
              :quote="quote"
              :billingAddress="billingAddress"
              :shippingAddress="shippingAddress"
              :uiShippingSameAsBilling="uiShippingSameAsBilling"
              @onSelectedUser="selectedUser"
              @onSaveUser="saveUser"
              @nextTab="onNextTab"
              data-cy="project-user-company-tab"
            />
          </v-tab-item>
          <v-tab-item transition="false">
            <ProjectSetupPage
              ref="setup"
              :isAdmin="isAdmin"
              :editMode="editMode"
              :project="project"
              :quote="quote"
              :dutDetails="dutDetails"
              :dutConfigs="dutConfigs"
              :fixtureConfig="getFixtureConfig()"
              :fixturesConfigs="fixturesConfigs"
              :inputValid="!!checkInputValid(2)"
              :projectType="projectType"
              :projectTimeline="projectTimeline"
              :fixtureWiring="fixtureWiring"
              @saveData="createProject"
              @updateData="updateProject"
              @nextTab="onNextTab"
              @onUpdateUIState="updateProjectDetails"
              @onDownloadFile="downloadFile"
              @refreshAddresses="refreshAddresses"
            />
          </v-tab-item>
          <v-tab-item transition="false">
            <DutDetailsPage
              ref="dut0"
              :calledFromQuote="false"
              :editMode="editMode"
              :tab="tab"
              :dutIndex="0"
              :quoteTabs="projectTabs"
              :quote="quote"
              :dutDetails="
                dutConfigs.length > 0
                  ? getDutDetails(dutConfigs[0].dut_details)
                  : {}
              "
              :dutFieldDescriptions="dutFieldDescriptions"
              :isAdmin="isAdmin"
              :project="project"
              :currentGerbers="gerberFiles[0]"
              :dutConfig="dutConfigs[0]"
              :isInitialState="isInitialState"
              :parsedJsonBlob="parsedJsonBlob"
              :parsedTestPoints="parsedTestPoints"
              :parsedGuidePins="parsedGuidePins"
              :parsedPressurePins="parsedPressurePins"
              :parsedHoles="parsedHoles"
              :parsedDutData="parsedDutData"
              :parsedMissingHeaders="parsedMissingHeaders"
              :testpointLayers="testpointLayers"
              :testpointTypes="testpointTypes"
              :pcbStackup="pcbStackup[0] ? pcbStackup[0] : blankSvg"
              :dutUnits="dutUnits"
              :isQuoteIncomplete="isQuoteIncomplete"
              :inputValid="!!checkInputValid(3)"
              @nextTab="onNextTab"
              @onSaveAllDutFromModal="saveAllDutFromModal"
              @onSaveAllDutData="saveAllDutData"
              @onSaveGerberFiles="saveGerberFiles"
              @onUpdateQuoteDutDetails="updateQuoteDutDetails"
              @scrollToTop="onScrollToTop"
              @deleteCurrentGerbers="deleteAllGerbers($event, gerberFiles[0])"
              @deleteCurrentDut="deleteAllDut"
              @downloadGerber="downloadGerberHandler"
              @parseTestPoint="parseTestPoint"
              @onSaveParsedDUT="saveParsedDUT"
            />
          </v-tab-item>
          <v-tab-item transition="false">
            <DutDetailsPage
              ref="dut1"
              :calledFromQuote="false"
              :editMode="editMode"
              :tab="tab"
              :dutIndex="1"
              :quoteTabs="projectTabs"
              :quote="quote"
              :dutDetails="
                dutConfigs.length > 1
                  ? getDutDetails(dutConfigs[1].dut_details)
                  : {}
              "
              :dutFieldDescriptions="dutFieldDescriptions"
              :isAdmin="isAdmin"
              :project="project"
              :currentGerbers="gerberFiles[1]"
              :dutConfig="dutConfigs[1]"
              :isInitialState="isInitialState"
              :parsedJsonBlob="parsedJsonBlob"
              :parsedTestPoints="parsedTestPoints"
              :parsedGuidePins="parsedGuidePins"
              :parsedPressurePins="parsedPressurePins"
              :parsedHoles="parsedHoles"
              :parsedDutData="parsedDutData"
              :parsedMissingHeaders="parsedMissingHeaders"
              :testpointLayers="testpointLayers"
              :testpointTypes="testpointTypes"
              :pcbStackup="pcbStackup[1] ? pcbStackup[1] : blankSvg"
              :dutUnits="dutUnits"
              :isQuoteIncomplete="isQuoteIncomplete"
              :inputValid="!!checkInputValid(4)"
              @nextTab="onNextTab"
              @onSaveAllDutFromModal="saveAllDutFromModal"
              @onSaveAllDutData="saveAllDutData"
              @onSaveGerberFiles="saveGerberFiles"
              @onUpdateQuoteDutDetails="updateQuoteDutDetails"
              @scrollToTop="onScrollToTop"
              @deleteCurrentGerbers="deleteAllGerbers($event, gerberFiles[1])"
              @deleteCurrentDut="deleteAllDut"
              @downloadGerber="downloadGerberHandler"
              @parseTestPoint="parseTestPoint"
              @onSaveParsedDUT="saveParsedDUT"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </v-sheet>
  </v-card>
</template>
<script>
//import pcbStackup from "pcb-stackup";
import {
  fileDownloader,
  getFileString,
  getFileBlob,
  gerbersToZip,
} from "@/helpers/basehelper.js";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ProjectTemplate",
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    activeTab: {
      type: Number,
      default: null,
    },
  },
  components: {
    UserCompanyPage: () => import("./UserCompanyPage.vue"),
    ProjectSetupPage: () => import("./ProjectSetupPage.vue"),
    DutDetailsPage: () => import("@/modules/quotes/views/DutDetailsPage.vue"),
  },
  data() {
    return {
      tab: 0,
      DEFAULT_PCB_THICKNESS: 1.6,
      EDITABLE_STATUS: "incomplete",
      DEFAULT_FD_VERSION: 1,
      addressIdToDelete: null,
    };
  },
  computed: {
    ...mapGetters({
      projectUser: "projects/projectUser",
      projectCompany: "projects/company",
      project: "projects/selProject",
      fixturesConfigs: "projects/fixturesConfigs",
      dutConfigs: "projects/dutConfigs",
      dutDetails: "projects/dutDetails",
      gerberFiles: "projects/gerberFiles",
      pcbStackup: "projects/pcbStackup",
      quote: "projects/quote",
      dutFieldDescriptions: "quotes/dutFieldDescriptions",
      parsedJsonBlob: "quotes/parsedJsonBlob",
      parsedTestPoints: "quotes/parsedTestPoints",
      parsedGuidePins: "quotes/parsedGuidePins",
      parsedPressurePins: "quotes/parsedPressurePins",
      parsedHoles: "quotes/parsedHoles",
      parsedDutData: "quotes/parsedDutData",
      parsedMissingHeaders: "quotes/parsedMissingHeaders",
      blankSvg: "quotes/blankSvg",
      dutUnits: "quotes/dutUnits",
      //quoteStatus: "quotes/quoteStatus",
      projectType: "quotes/projectType",
      projectTimeline: "quotes/projectTimeline",
      fixtureWiring: "quotes/fixtureWiring",
      billingAddress: "quotes/billingAddress",
      shippingAddress: "quotes/shippingAddress",
      testpointLayers: "dutconfigs/testpointLayers",
      testpointTypes: "dutconfigs/testpointTypes",
      fixtureDesign: "fixturedesign/fixtureDesign",
      projectDetails: "ui/projectDetails",
      dutUiDetails: "ui/dutUiDetails",
      projectTabs: "ui/projectTabs",
      uiBillingAddress: "ui/billingAddress",
      uiShippingAddress: "ui/shippingAddress",
      uiShippingSameAsBilling: "ui/shippingSameAsBilling",
      isAdmin: "auth/isAdmin",
      user: "auth/user",
    }),
    fixtureDesignId() {
      return Object.keys(this.project.fixture_design_details)[0];
    },
    isQuoteIncomplete() {
      return true;
    },
    isInitialState() {
      return this.fixtureDesign.state === 1;
    },
    isBillingAddressEdited() {
      return (
        this.uiBillingAddress.name != this.billingAddress.name ||
        this.uiBillingAddress.company_name !=
          this.billingAddress.company_name ||
        this.uiBillingAddress.email != this.billingAddress.email ||
        this.uiBillingAddress.phone != this.billingAddress.phone ||
        this.uiBillingAddress.street1 != this.billingAddress.street1 ||
        this.uiBillingAddress.street2 != this.billingAddress.street2 ||
        this.uiBillingAddress.city != this.billingAddress.city ||
        this.uiBillingAddress.state != this.billingAddress.state ||
        this.uiBillingAddress.country != this.billingAddress.country ||
        this.uiBillingAddress.zip != this.billingAddress.zip
      );
    },
  },
  methods: {
    fileDownloader,
    getFileString,
    getFileBlob,
    gerbersToZip,
    ...mapActions({
      postProject: "projects/saveProject",
      patchProject: "projects/updateProject",
      fetchProjectFlow: "projects/fetchProjectFlow",
      removeProject: "projects/deleteProject",
      createDutConfigs: "projects/createDutConfigs",
      updateDutConfigs: "projects/updateDutConfigs",
      fetchDutConfigs: "projects/getDutConfigsById",
      initDutConfigs: "projects/initDutConfigs",
      saveDutDetails: "projects/saveDutDetail",
      initDutDetails: "projects/initDutDetails",
      getDutDetailsById: "projects/getDutDetailsById",
      createQuote: "projects/saveQuote",
      fetchUserById: "projects/fetchUserById",
      fetchCompanyById: "projects/fetchCompanyById",
      initUserCompany: "projects/initUserCompany",
      deleteDutConfigs: "dutconfigs/removeDutById",
      patchBulkGerbers: "dutconfigs/patchBulkGerbers",
      saveTestPointsBlob: "dutconfigs/saveTestPointsBlob",
      postFixtureDesign: "fixturedesign/saveFixtureDesign",
      patchFixtureDesign: "fixturedesign/patchFixtureDesign",
      fetchFixtureDesign: "fixturedesign/getFixtureDesign",
      saveDesignFilePackage: "fixturedesign/postDesignFilePackage",
      convertTestPoint: "quotes/parseTestPointFile",
      saveQuoteAddress: "quotes/saveQuoteAddress",
      deleteQuoteAddress: "quotes/deleteQuoteAddress",
      postGerberLayerFile: "quotes/postGerberLayerFile",
      removeGerberLayerFile: "quotes/removeGerberLayerFile",
      updateProjectTabs: "ui/updateProjectTabs",
      toggleLoading: "ui/loading",
    }),

    async createProject({ payload, dut_config, quote }) {
      let projectObj;
      try {
        const projectPayload = {
          project_name: payload.project_name,
          user: this.projectUser.pk,
        };
        projectObj = await this.postProject(projectPayload);
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
      try {
        let inputData = new FormData();
        inputData.append("project", projectObj.pk);
        inputData.append("user", projectObj.user);
        inputData.append("fixture_config", payload.fixture_config);
        inputData.append("version", payload.version);
        if (quote) inputData.append("quote", quote);
        const response = await this.postFixtureDesign(inputData);
        //Post to DFP
        this.toggleLoading("Constructing thumbnail");
        let designData = new FormData();
        designData.append("user", response.data.user);
        designData.append("project", response.data.project);
        designData.append("fixture_design", response.data.pk);
        designData.append("version", this.DEFAULT_FD_VERSION);
        designData.append("active", true);
        designData.append(
          "thumbnail",
          await this.getFileBlob(payload.thumbnail),
          "thumbnail.jpg"
        );
        this.toggleLoading();
        await this.saveDesignFilePackage(designData);
        //End Post to DFP

        //Post to DC
        this.toggleLoading("Creating DUT Configs");
        this.initDutConfigs();
        for (let i = 1; i <= dut_config.number_of_duts; i++) {
          console.log("for", i);
          let dutData = new FormData();
          dutData.append("name", dut_config[`dut_name_${i}`]);
          dutData.append("pcb_thickness", this.DEFAULT_PCB_THICKNESS);
          dutData.append("user", projectObj.user);
          dutData.append("project", projectObj.pk);
          dutData.append("fixture_design", response.data.pk);
          await this.createDutConfigs({ payload: dutData, index: i - 1 });
        }
        this.toggleLoading();
        //End Post to DC
        //Post to Quote and DD
        this.toggleLoading("Refreshing Project view");
        await this.fetchProjectFlow(projectObj.pk);
        this.toggleLoading();
        await this.createDutDetails({ quote });
        this.updateProjectTabs(this.project);
        this.toggleLoading("One moment please, fetching Fixture Design");
        await this.fetchFixtureDesign({
          params: { project_id: this.project.pk },
        });
        this.toggleLoading();
        this.onNextTab();
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
        await this.removeProject(projectObj.pk);
      }
    },
    async updateProject({ payload, dut_config, quote }) {
      try {
        this.toggleLoading("Updating DUT Configs");
        const ddId =
          this.dutConfigs.length > 1 ? this.dutConfigs[1].dut_details : null;
        this.initDutConfigs();
        if (
          dut_config.number_of_duts <=
          Object.keys(this.project.dut_config_details).length
        ) {
          for (let i = 0; i < dut_config.number_of_duts; i++) {
            let configData = new FormData();
            configData.append("name", dut_config[`dut_name_${i + 1}`]);
            const configPayload = {
              pk: Object.keys(this.project.dut_config_details)[i],
              data: configData,
            };
            await this.updateDutConfigs(configPayload);
            let detailData = new FormData();
            detailData.append("dut_name", dut_config[`dut_name_${i + 1}`]);
            const detailPayload = {
              editMode: true,
              id: this.dutDetails[i].id,
              data: detailData,
              index: i,
            };
            await this.saveDutDetails(detailPayload);
          }
        } else if (
          dut_config.number_of_duts >
          Object.keys(this.project.dut_config_details).length
        ) {
          // Update First DUT
          let configData = new FormData();
          configData.append("name", dut_config[`dut_name_1`]);
          const configPayload = {
            pk: Object.keys(this.project.dut_config_details)[0],
            data: configData,
          };
          await this.updateDutConfigs(configPayload);
          // Create Second DUT
          let newDutData = new FormData();
          newDutData.append("name", dut_config[`dut_name_2`]);
          newDutData.append("pcb_thickness", this.DEFAULT_PCB_THICKNESS);
          newDutData.append("user", this.project.user);
          newDutData.append("project", this.project.pk);
          newDutData.append(
            "fixture_design",
            Object.keys(this.project.fixture_design_details)[0]
          );
          await this.createDutConfigs({ payload: newDutData });
        }
        this.toggleLoading();

        if (
          dut_config.number_of_duts <
          Object.keys(this.project.dut_config_details).length
        ) {
          await this.deleteDutConfigs(
            Object.keys(this.project.dut_config_details)[1]
          );
          this.$store.commit("projects/DELETE_DUT_DETAIL", ddId);
          this.$refs["setup"] && this.$refs["setup"].initDutName2();
        }

        await this.patchQuoteId(quote);

        const projectPayload = {
          id: this.project.pk,
          data: {
            project_name: payload.project_name,
            user: this.projectUser.pk,
          },
        };
        await this.patchProject(projectPayload);
        await this.createDutDetails({ startIndex: 1 });
        this.updateProjectTabs(this.project);
        this.onNextTab();
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    getFixtureConfig() {
      if (!this.editMode) return null;
      const record = this.fixturesConfigs.find((element) => {
        return element.pk == this.fixtureDesign.fixture_config.pk;
      });
      return record
        ? {
            pk: record.pk,
            name: record.blurb,
            description: record.description,
            thumbnail: record.thumbnail,
          }
        : null;
    },
    getDutDetails(dutDetailsId) {
      const record = this.dutDetails.find((element) => {
        return element.id == dutDetailsId;
      });
      return record ? record : null;
    },
    async deleteAllDut(dutIndex) {
      try {
        this.toggleLoading("Removing DUT file");
        let detailsData = new FormData();
        detailsData.append("test_point_file", new File([], ""));
        detailsData.append("units", 1);
        detailsData.append("x_origin", 0);
        detailsData.append("y_origin", 0);
        detailsData.append("pcb_width", "");
        detailsData.append("pcb_height", "");
        detailsData.append("pcb_thickness", 1.6);
        detailsData.append("dut_file_data", "{}");
        const detailsPayload = {
          editMode: true,
          id: this.dutDetails[dutIndex].id,
          data: detailsData,
          index: dutIndex,
        };
        await this.saveDutDetails(detailsPayload);
        this.$refs[`dut${dutIndex}`] &&
          this.$refs[`dut${dutIndex}`].initDutFields();
        this.toggleLoading();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    isUserDetailsValid() {
      return (
        (this.editMode || Object.keys(this.project).length > 1
          ? this.project.user == this.projectUser.pk
          : true) &&
        (Object.keys(this.quote).length > 0
          ? this.billingAddress &&
            this.billingAddress.name == this.uiBillingAddress.name
          : true) &&
        (Object.keys(this.quote).length > 0
          ? this.billingAddress.company_name ==
            this.uiBillingAddress.company_name
          : true) &&
        (Object.keys(this.quote).length > 0
          ? this.billingAddress.email == this.uiBillingAddress.email
          : true) &&
        (Object.keys(this.quote).length > 0
          ? this.billingAddress.phone == this.uiBillingAddress.phone
          : true) &&
        (Object.keys(this.quote).length > 0
          ? this.billingAddress.street1 == this.uiBillingAddress.street1
          : true) &&
        (Object.keys(this.quote).length > 0
          ? this.billingAddress.street2 == this.uiBillingAddress.street2
          : true) &&
        (Object.keys(this.quote).length > 0
          ? this.billingAddress.city == this.uiBillingAddress.city
          : true) &&
        (Object.keys(this.quote).length > 0
          ? this.billingAddress.state == this.uiBillingAddress.state
          : true) &&
        (Object.keys(this.quote).length > 0
          ? this.billingAddress.country == this.uiBillingAddress.country
          : true) &&
        (Object.keys(this.quote).length > 0
          ? this.billingAddress.zip == this.uiBillingAddress.zip
          : true) &&
        (Object.keys(this.quote).length > 0
          ? this.quote.shipping_same_as_billing == this.uiShippingSameAsBilling
          : true) &&
        (!this.uiShippingSameAsBilling
          ? Object.keys(this.quote).length > 0
            ? this.shippingAddress.name &&
              this.shippingAddress.name == this.uiShippingAddress.name
            : true
          : true) &&
        (!this.uiShippingSameAsBilling
          ? Object.keys(this.quote).length > 0
            ? this.shippingAddress &&
              this.shippingAddress.company_name ==
                this.uiShippingAddress.company_name
            : true
          : true) &&
        (!this.uiShippingSameAsBilling
          ? Object.keys(this.quote).length > 0
            ? this.shippingAddress &&
              this.shippingAddress.email == this.uiShippingAddress.email
            : true
          : true) &&
        (!this.uiShippingSameAsBilling
          ? Object.keys(this.quote).length > 0
            ? this.shippingAddress &&
              this.shippingAddress.phone == this.uiShippingAddress.phone
            : true
          : true) &&
        (!this.uiShippingSameAsBilling
          ? Object.keys(this.quote).length > 0
            ? this.shippingAddress &&
              this.shippingAddress.street1 == this.uiShippingAddress.street1
            : true
          : true) &&
        (!this.uiShippingSameAsBilling
          ? Object.keys(this.quote).length > 0
            ? this.shippingAddress &&
              this.shippingAddress.street2 == this.uiShippingAddress.street2
            : true
          : true) &&
        (!this.uiShippingSameAsBilling
          ? Object.keys(this.quote).length > 0
            ? this.shippingAddress &&
              this.shippingAddress.city == this.uiShippingAddress.city
            : true
          : true) &&
        (!this.uiShippingSameAsBilling
          ? Object.keys(this.quote).length > 0
            ? this.shippingAddress &&
              this.shippingAddress.state == this.uiShippingAddress.state
            : true
          : true) &&
        (!this.uiShippingSameAsBilling
          ? Object.keys(this.quote).length > 0
            ? this.shippingAddress &&
              this.shippingAddress.country == this.uiShippingAddress.country
            : true
          : true) &&
        (!this.uiShippingSameAsBilling
          ? Object.keys(this.quote).length > 0
            ? this.shippingAddress &&
              this.shippingAddress.zip == this.uiShippingAddress.zip
            : true
          : true)
      );
    },
    isProjectDetailsValid() {
      return (
        this.projectDetails.project_name &&
        this.projectDetails.project_name == this.project.project_name &&
        this.projectDetails.number_of_duts &&
        this.projectDetails.number_of_duts ==
          Object.keys(this.project.dut_config_details).length &&
        (Object.keys(this.project.dut_config_details).length > 0
          ? this.projectDetails.dut_name_1 &&
            this.projectDetails.dut_name_1 ==
              Object.values(this.project.dut_config_details)[0].name
          : true) &&
        (Object.keys(this.project.dut_config_details).length > 1
          ? this.projectDetails.dut_name_2 &&
            this.projectDetails.dut_name_2 ==
              Object.values(this.project.dut_config_details)[1].name
          : true) &&
        ((Object.keys(this.quote).length > 0 && this.fixtureDesign.quote) ||
          (Object.keys(this.quote).length == 0 && !this.fixtureDesign.quote))
      );
    },
    isDutDetails1Valid() {
      return (
        this.dutUiDetails.dut_name_0 &&
        this.dutUiDetails.dut_name_0 == this.dutDetails[0].dut_name &&
        this.dutUiDetails.pcb_width_0 &&
        this.dutUiDetails.pcb_width_0 == this.dutDetails[0].pcb_width &&
        this.dutUiDetails.pcb_height_0 &&
        this.dutUiDetails.pcb_height_0 == this.dutDetails[0].pcb_height &&
        this.dutUiDetails.pcb_thickness_0 &&
        this.dutUiDetails.pcb_thickness_0 == this.dutDetails[0].pcb_thickness &&
        this.dutDetails[0].test_point_file &&
        this.dutDetails[0].step_file &&
        ((this.gerberFiles[0]?.length > 0 &&
          !this.dutUiDetails.zipped_gerbers_0) ||
          this.dutDetails[0].odb_zip ||
          this.dutDetails[0].ipc_zip) &&
        this.dutUiDetails.flex_board_0 == this.dutDetails[0].flex_board &&
        this.dutUiDetails.dual_sided_probing_0 ==
          this.dutDetails[0].dual_sided_probing &&
        !this.dutUiDetails.test_point_file_0 &&
        !this.dutUiDetails.step_file_0 &&
        !this.dutUiDetails.gerber_zip_0 &&
        !this.dutUiDetails.odb_zip_0 &&
        !this.dutUiDetails.ipc_zip_0 &&
        !this.dutUiDetails.schematic_file_0 &&
        !this.dutUiDetails.other_file_0 &&
        this.dutUiDetails.additional_comments_0 ==
          this.dutDetails[0].additional_comments
      );
    },
    isDutDetails2Valid() {
      return (
        this.dutUiDetails.dut_name_1 &&
        this.dutUiDetails.dut_name_1 == this.dutDetails[1]?.dut_name &&
        this.dutUiDetails.pcb_width_1 &&
        this.dutUiDetails.pcb_width_1 == this.dutDetails[1]?.pcb_width &&
        this.dutUiDetails.pcb_height_1 &&
        this.dutUiDetails.pcb_height_1 == this.dutDetails[1]?.pcb_height &&
        this.dutUiDetails.pcb_thickness_1 &&
        this.dutUiDetails.pcb_thickness_1 ==
          this.dutDetails[1]?.pcb_thickness &&
        this.dutDetails[1]?.test_point_file &&
        this.dutDetails[1]?.step_file &&
        ((this.gerberFiles[1]?.length > 0 &&
          !this.dutUiDetails.zipped_gerbers_1) ||
          this.dutDetails[1].odb_zip ||
          this.dutDetails[1].ipc_zip) &&
        this.dutUiDetails.flex_board_1 == this.dutDetails[1]?.flex_board &&
        this.dutUiDetails.dual_sided_probing_1 ==
          this.dutDetails[1]?.dual_sided_probing &&
        !this.dutUiDetails.test_point_file_1 &&
        !this.dutUiDetails.step_file_1 &&
        !this.dutUiDetails.gerber_zip_1 &&
        !this.dutUiDetails.odb_zip_1 &&
        !this.dutUiDetails.ipc_zip_1 &&
        !this.dutUiDetails.schematic_file_1 &&
        !this.dutUiDetails.other_file_1 &&
        this.dutUiDetails.additional_comments_1 ==
          this.dutDetails[1]?.additional_comments
      );
    },
    checkInputValid(index) {
      if (index == 0) {
        return true;
      } else if (index == 1) {
        return this.isUserDetailsValid();
      } else if (index == 2) {
        return this.isUserDetailsValid() && this.isProjectDetailsValid();
      } else if (index == 3) {
        return (
          this.isUserDetailsValid() &&
          this.isProjectDetailsValid() &&
          this.isDutDetails1Valid()
        );
      } else if (index == 4) {
        return (
          this.isProjectDetailsValid() &&
          this.isDutDetails1Valid() &&
          this.isDutDetails2Valid()
        );
      } else {
        return false;
      }
    },
    checkIconValid(index) {
      if (index == 0) {
        return this.isUserDetailsValid();
      } else if (index == 1) {
        return this.isProjectDetailsValid();
      } else if (index == 2) {
        return this.isDutDetails1Valid();
      } else if (index == 3) {
        return this.isDutDetails2Valid();
      } else {
        return false;
      }
    },
    onNextTab() {
      this.tab += 1;
      !this.isAdmin && this.onScrollToTop();
    },
    onPreviousTab() {
      this.tab -= 1;
    },
    onScrollToTop() {
      this.$vuetify.goTo(0, { container: "#scrollBody" });
    },
    updateDutQuote(payload) {
      this.$refs[`setup`] && this.$refs[`setup`].updateDutName(payload);
    },
    updateProjectDetails(payload) {
      this.$store.dispatch("ui/updateProjectDetails", payload);
    },
    updateQuoteDutDetails(payload) {
      this.$store.dispatch("ui/updateQuoteDutDetails", payload);
    },
    async saveAllDutData({ payload, nextTab, dutIndex, blobs, configData }) {
      const { testpoints, guidepins, pressurepins } = blobs;
      try {
        const configPayload = {
          pk: Object.keys(this.project.dut_config_details)[dutIndex],
          data: configData,
        };
        this.toggleLoading("Saving DUT Details");
        await this.saveDutDetails(Object.assign(payload, { index: dutIndex }));
        this.toggleLoading(
          "Saving DUT Configs, Test Points, Guide Pins and Pressure Pins"
        );
        await Promise.all([
          this.updateDutConfigs(configPayload),
          this.saveAllTestPoints({ testpoints, dutIndex }),
          this.saveAllGuidePins({ guidepins, dutIndex }),
          this.saveAllPressurePins({ pressurepins, dutIndex }),
        ]);
        this.toggleLoading();
        if (nextTab) {
          this.onNextTab();
        } else {
          this.$router.replace({
            name: "configure",
            params: {
              id: this.project.pk,
              did: Object.keys(this.project.dut_config_details)[0],
            },
          });
        }
        this.$refs[`dut${dutIndex}`] &&
          this.$refs[`dut${dutIndex}`].initializeAllState();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async saveAllTestPoints({ testpoints, dutIndex }) {
      try {
        const tpData = {
          test_points: testpoints,
        };
        console.log("TP Blob Patch Payload", testpoints, tpData);
        await this.saveTestPointsBlob({
          project_id: this.project.pk,
          dut_id: Object.keys(this.project.dut_config_details)[dutIndex],
          data: tpData,
          data_type: "test-points",
        });
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async saveAllGuidePins({ guidepins, dutIndex }) {
      try {
        const gpData = {
          guide_pins: guidepins,
        };
        await this.saveTestPointsBlob({
          project_id: this.project.pk,
          dut_id: Object.keys(this.project.dut_config_details)[dutIndex],
          data: gpData,
          data_type: "guide-pins",
        });
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async saveAllPressurePins({ pressurepins, dutIndex }) {
      try {
        const ppData = {
          pressure_pins: pressurepins,
        };
        await this.saveTestPointsBlob({
          project_id: this.project.pk,
          dut_id: Object.keys(this.project.dut_config_details)[dutIndex],
          data: ppData,
          data_type: "pressure-pins",
        });
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async saveAllDutFromModal({ payload, dutIndex }) {
      try {
        this.toggleLoading("Saving DUT Details");
        await this.saveDutDetails(Object.assign(payload, { index: dutIndex }));
        this.toggleLoading();
        this.$refs[`dut${dutIndex}`] &&
          this.$refs[`dut${dutIndex}`].initStateAfterSave({
            varStr: "formDut1TestPoint",
            uiStr: "test_point_file",
            value: null,
          });
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async saveDutFileData({ id, key, value, index }) {
      let inputData = new FormData();
      inputData.append(key, value);

      const actionPayload = {
        editMode: true,
        id,
        data: inputData,
        index,
      };

      try {
        await this.saveDutDetails(actionPayload);
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async saveGerberFiles({
      dutIndex,
      gerbers,
      zipped_file,
      withExistingGerbers,
      isBackwardCompatibility,
    }) {
      if (gerbers.gerberLayers.length > 0) {
        this.toggleLoading("Saving Gerber Files");

        try {
          if (withExistingGerbers) {
            await this.removeGerberLayerFile({
              dutDetailsId: this.dutDetails[dutIndex].id,
              index: dutIndex,
            });
            await this.saveDutFileData({
              id: this.dutConfigs[dutIndex].dut_details,
              key: "gerber_zip",
              value: new File([], ""),
              index: dutIndex,
            });
          }

          for (let i = 0; i < gerbers.gerberLayers.length; i++) {
            let gerberPayload = new FormData();
            gerberPayload.append("layer", gerbers.gerberLayers[i].layer);
            gerberPayload.append("side", gerbers.gerberLayers[i].side);
            gerberPayload.append("svg_data", gerbers.gerberLayers[i].svg);
            gerberPayload.append("dut_details", this.dutDetails[dutIndex].id);
            gerberPayload.append(
              "file",
              new Blob([gerbers.gerberLayers[i].gerber], {
                type: "application/vnd.gerber",
              }),
              gerbers.gerberLayers[i].file
            );
            await this.postGerberLayerFile(gerberPayload);
          }
        } catch (err) {
          this.toggleLoading("Rolling back saved Gerber files");
          await this.removeGerberLayerFile({
            dutDetailsId: this.dutDetails[dutIndex].id,
            index: dutIndex,
          });
          this.toggleLoading();
          this.$store.dispatch("ui/snackLink", {
            message1:
              "Something went wrong while saving Gerber layers, you may upload another zip file or contact ",
            message2: " for help.",
            email: true,
            link: {
              url: "mailto:support@fixturfab.com",
              text: "support@fixturfab.com",
            },
          });
          return;
        }

        try {
          if (this.dutDetails.length == 0) {
            await this.createDutDetails({ zipped_file });
          } else {
            if (!isBackwardCompatibility) {
              await this.saveDutFileData({
                id: this.dutConfigs[dutIndex].dut_details,
                key: "gerber_zip",
                value: zipped_file,
                index: dutIndex,
              });
            } else {
              await this.getDutDetailsById({
                id: this.dutConfigs[dutIndex].dut_details,
                index: dutIndex,
              });
            }
          }

          await this.updateDutConfigs({
            pk: Object.keys(this.project.dut_config_details)[dutIndex],
            data: await this.gerbersToZip(gerbers.gerberFiles),
          });
          this.toggleLoading();
        } catch (err) {
          this.toggleLoading();
          this.$store.commit(
            "ui/SNACK_BAR",
            err.message || "Failed to process request, try later."
          );
        }
      }
    },
    async parseTestPoint({ payload, dutId }) {
      try {
        this.toggleLoading("Parsing");
        await this.convertTestPoint(payload);
        this.toggleLoading();
        this.$refs[`dut${dutId}`] && this.$refs[`dut${dutId}`].updateConfig2d();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
        this.$refs[`dut${dutId}`] &&
          this.$refs[`dut${dutId}`].initTestPointFile();
      }
    },
    backToPrevious() {
      this.$router.push({ name: "projects" });
    },
    async deleteAllGerbers(dutIndex, payload) {
      console.log(payload, dutIndex);
      try {
        this.toggleLoading("Removing Gerbers and all relevant data");
        let configData = new FormData();
        configData.append("pcb_stackup", "");
        configData.append("zipped_gerber_files", new File([], ""));
        const configPayload = {
          pk: Object.keys(this.project.dut_config_details)[dutIndex],
          data: configData,
        };
        let detailsData = new FormData();
        detailsData.append("gerber_zip", new File([], ""));
        const detailsPayload = {
          editMode: true,
          id: this.dutConfigs[dutIndex].dut_details,
          data: detailsData,
          index: dutIndex,
        };
        console.log(configPayload, detailsPayload);
        await this.removeGerberLayerFile({
          dutDetailsId: this.dutDetails[dutIndex].id,
          index: dutIndex,
        });
        await this.updateDutConfigs(configPayload);
        await this.saveDutDetails(detailsPayload);
        this.toggleLoading();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    downloadGerberHandler({ url, file }) {
      this.toggleLoading("Downloading, one moment please");
      this.fileDownloader({ url, file });
      this.toggleLoading();
    },
    async saveParsedDUT({ testpoint_file, dut_settings, parsed_json_blob }) {
      // Post Quote and DD if no matching Quote on FD
      if (this.dutDetails.length == 0) {
        await this.createDutDetails({
          testpoint_file,
          dut_settings,
          parsed_json_blob,
        });
      }
    },
    async createDutDetails({
      zipped_file = null,
      testpoint_file = null,
      dut_settings = null,
      parsed_json_blob = {},
      quote = null,
      startIndex = 0,
    }) {
      try {
        this.toggleLoading("Saving DUT Details");
        startIndex == 0 && this.initDutDetails();
        for (
          let i = startIndex;
          i < Object.keys(this.project.dut_config_details).length;
          i++
        ) {
          let dutData = new FormData();
          dutData.append("user", this.projectUser.pk);
          dutData.append(
            "dut_name",
            Object.values(this.project.dut_config_details)[i].name
          );
          dutData.append("dut_file_data", JSON.stringify(parsed_json_blob));
          if (zipped_file) dutData.append("gerber_zip", zipped_file);
          if (testpoint_file) dutData.append("test_point_file", testpoint_file);
          if (dut_settings) {
            dutData.append("units", dut_settings.units);
            dutData.append("x_origin", dut_settings.x_origin);
            dutData.append("y_origin", dut_settings.y_origin);
            dutData.append("pcb_width", dut_settings.dut_width);
            dutData.append("pcb_height", dut_settings.dut_length);
            dutData.append("pcb_thickness", dut_settings.dut_height);
          }
          if (quote) dutData.append("quote", quote);
          const dutDetailsPayload = {
            editMode: false,
            id: null,
            data: dutData,
            index: i,
          };
          const response_detail = await this.saveDutDetails(dutDetailsPayload);
          this.updateUIState({
            key: `dut_name_${i}`,
            value: response_detail.dut_name,
          });
          let ddData = new FormData();
          ddData.append("dut_details", response_detail.id);
          await this.updateDutConfigs({
            pk: Object.keys(this.project.dut_config_details)[i],
            data: ddData,
          });
        }
        this.toggleLoading();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async patchQuoteId(quote) {
      try {
        let formData = new FormData();
        formData.append("quote", quote ? quote : "");
        await this.patchFixtureDesign({
          pk: this.fixtureDesignId,
          data: formData,
        });
        this.toggleLoading("Update DUT Details");
        let dutData = new FormData();
        dutData.append("quote", quote ? quote : "");
        for (let i = 0; i < this.dutDetails.length > 0; i++) {
          const dutDetailsPayload = {
            editMode: true,
            id: this.dutDetails[i].id,
            data: dutData,
            index: i,
          };
          await this.saveDutDetails(dutDetailsPayload);
        }
        this.toggleLoading();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async downloadFile({ url, filename }) {
      const actionPayload = {
        url,
        file: filename,
      };
      this.toggleLoading("Downloading, one moment please");
      await this.fileDownloader(actionPayload);
      this.toggleLoading();
    },
    async selectedUser(id) {
      this.initUserCompany();
      try {
        this.toggleLoading("Registering user");
        const response = await this.fetchUserById(id);
        console.log(response);
        if (response.data.groups.length > 0) {
          await this.fetchCompanyById({ id: response.data.groups[0].id });
        }
        this.$refs["user"] && this.$refs["user"].refreshInputFields();
        this.toggleLoading();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    updateUIState(payload) {
      this.$store.dispatch("ui/updateQuoteDutDetails", payload);
    },
    async saveUser(userPk) {
      try {
        if (Object.keys(this.project).length > 1) {
          const projectPayload = {
            id: this.project.pk,
            data: {
              user: userPk,
            },
          };
          await this.patchProject(projectPayload);
        }
        await this.onSaveAddressData();
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async onSaveAddressData() {
      const billingAddressPayload = {
        editMode: this.editMode || Object.keys(this.quote).length > 0,
        id:
          (this.editMode || Object.keys(this.quote).length > 0) &&
          this.billingAddress &&
          Object.keys(this.billingAddress).length > 1
            ? this.billingAddress.id
            : null,
        data: this.uiBillingAddress,
        isBilling: true,
      };
      try {
        this.toggleLoading("Saving Address Data");
        let resBillingAddress;
        if (this.isBillingAddressEdited) {
          resBillingAddress = await this.saveQuoteAddress(
            billingAddressPayload
          );
        } else {
          resBillingAddress = { id: this.billingAddress.id };
        }

        let resShippingAddress = null;
        if (this.uiShippingSameAsBilling) {
          if (
            (this.editMode || Object.keys(this.quote).length > 0) &&
            this.quote.billing_address != this.quote.shipping_address
          ) {
            this.addressIdToDelete = this.quote.shipping_address;
          }
        } else {
          const shippingAddressPayload = {
            editMode: false,
            id: null,
            data: this.uiShippingAddress,
            isBilling: false,
          };
          resShippingAddress = await this.saveQuoteAddress(
            shippingAddressPayload
          );
          console.log("Shipping Address Posted", resShippingAddress);
        }
        const quotePayload = {
          editMode: this.editMode || Object.keys(this.quote).length > 0,
          id:
            this.editMode || Object.keys(this.quote).length > 0
              ? this.quote.id
              : null,
          data: {
            billing_address: resBillingAddress.id,
            shipping_address: !this.uiShippingSameAsBilling
              ? resShippingAddress.id
              : resBillingAddress.id,
            shipping_same_as_billing: this.uiShippingSameAsBilling,
          },
        };
        await this.createQuote(quotePayload);
        if (this.uiShippingSameAsBilling && this.addressIdToDelete) {
          await this.deleteQuoteAddress(this.addressIdToDelete);
          this.$store.commit("quotes/SET_SHIPPING_ADDRESS", null);
          this.addressIdToDelete = null;
        }
        this.toggleLoading();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    refreshAddresses() {
      this.$refs["user"] && this.$refs["user"].refreshAddresses();
    },
  },
  async mounted() {
    if (this.activeTab) this.tab = this.activeTab;
  },
};
</script>
<style scoped>
.scroller {
  height: calc(100vh - 190px);
  overflow-y: auto;
}
</style>
